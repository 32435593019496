// Code generated by internal/query-builder/external_kpi/gen/gen.go, DO NOT EDIT.
// to regenerate run go generate ./... in the root of the project

export interface Metric {
  id: KnownMetric;
  label: string;
  isDimension: false;
}

export interface Dimension {
  id: string;
  label: string;
  isDimension: true;
}

type PossibleColumns = Metric | Dimension;

export enum KnownMetric {
  CustomerSatisfaction = "customer_satisfaction_ratio",
  HighPrice = "high_price_ratio",
  AssortmentIssues = "assortment_issues_ratio",
  OtherIssues = "other_issues_ratio",
  InterestedCustomers = "interested_customers",
  AvgStareTime = "stare_time_avg",
  StaredNumber = "stared_count",
  BypassersNumber = "bypassers_count",
  EngagementsCount = "engagements_count",
  EngagementsPerCustomer = "engagements_per_customer",
  AvgSpeed = "speed_avg_ms",
  AvgCSAT = "csat_avg",
  CSATDetected = "csat_detected",
  CSATNotDetected = "csat_not_detected",
  OverallCsatEventsCount = "overall_csat_events_count",
  PeopleCount = "people_count",
  AlertsCount = "alerts_count",
  ProcessedAlertsCount = "processed_alerts_count",
  AvgPlanogramCompliance = "planogram_compliance_avg",
  AvgPrice = "price",
  AvgCompetitorsPrice = "competitors_price",
  BasketShare = "basket_share",
  Sales = "sales",
}

export enum KnownDimension {
  Time = "time",
  Hour = "hour",
  Date = "date",
  Week = "week",
  HourOfTheDay = "hour_of_the_day",
  DayOfTheWeek = "day_of_the_week",
  Zone = "zone_id",
  ZoneInstance = "zone_instance_id",
  Shop = "shop_id",
  SensorType = "sensor_type",
  DataSource = "data_source",
  ShopName = "shop_name",
  ZoneName = "zone_name",
  DeviceMac = "device_mac",
  Person = "person_id",
  VisitStarted = "visit_started",
  VisitEnded = "visit_ended",
}

export enum KnownColumns {
  CustomerSatisfaction = "customer_satisfaction_ratio",
  HighPrice = "high_price_ratio",
  AssortmentIssues = "assortment_issues_ratio",
  OtherIssues = "other_issues_ratio",
  InterestedCustomers = "interested_customers",
  AvgStareTime = "stare_time_avg",
  StaredNumber = "stared_count",
  BypassersNumber = "bypassers_count",
  EngagementsCount = "engagements_count",
  EngagementsPerCustomer = "engagements_per_customer",
  AvgSpeed = "speed_avg_ms",
  AvgCSAT = "csat_avg",
  CSATDetected = "csat_detected",
  CSATNotDetected = "csat_not_detected",
  OverallCsatEventsCount = "overall_csat_events_count",
  PeopleCount = "people_count",
  AlertsCount = "alerts_count",
  ProcessedAlertsCount = "processed_alerts_count",
  AvgPlanogramCompliance = "planogram_compliance_avg",
  AvgPrice = "price",
  AvgCompetitorsPrice = "competitors_price",
  BasketShare = "basket_share",
  Sales = "sales",

  Time = "time",
  Hour = "hour",
  Date = "date",
  Week = "week",
  HourOfTheDay = "hour_of_the_day",
  DayOfTheWeek = "day_of_the_week",
  Zone = "zone_id",
  ZoneInstance = "zone_instance_id",
  Shop = "shop_id",
  SensorType = "sensor_type",
  DataSource = "data_source",
  ShopName = "shop_name",
  ZoneName = "zone_name",
  DeviceMac = "device_mac",
  Person = "person_id",
  VisitStarted = "visit_started",
  VisitEnded = "visit_ended",
}

export const KnownColumnsToNameMap = {
  zone_id: "Zone Name",
  shop_id: "Shop Name",

  data_source: "data_source",
};

export const KnownMetrics: Metric[] = [
  {
    id: KnownMetric.CustomerSatisfaction,
    label: "Customer satisfaction",
    isDimension: false,
  },
  {
    id: KnownMetric.HighPrice,
    label: "High Price",
    isDimension: false,
  },
  {
    id: KnownMetric.AssortmentIssues,
    label: "Assortment Issues",
    isDimension: false,
  },
  {
    id: KnownMetric.OtherIssues,
    label: "Other Issues",
    isDimension: false,
  },
  {
    id: KnownMetric.InterestedCustomers,
    label: "Interested Customers",
    isDimension: false,
  },
  {
    id: KnownMetric.AvgStareTime,
    label: "Avg. Dwell Time",
    isDimension: false,
  },
  {
    id: KnownMetric.StaredNumber,
    label: "Dwell Number",
    isDimension: false,
  },
  {
    id: KnownMetric.BypassersNumber,
    label: "Bypassers Number",
    isDimension: false,
  },
  {
    id: KnownMetric.EngagementsCount,
    label: "Engagements Number",
    isDimension: false,
  },
  {
    id: KnownMetric.EngagementsPerCustomer,
    label: "Avg. Engagements per Customer",
    isDimension: false,
  },
  {
    id: KnownMetric.AvgSpeed,
    label: "Avg. Speed",
    isDimension: false,
  },
  {
    id: KnownMetric.AvgCSAT,
    label: "C-SAT",
    isDimension: false,
  },
  {
    id: KnownMetric.CSATDetected,
    label: "Satisfaction detected",
    isDimension: false,
  },
  {
    id: KnownMetric.CSATNotDetected,
    label: "Neutral state",
    isDimension: false,
  },
  {
    id: KnownMetric.OverallCsatEventsCount,
    label: "Events Count",
    isDimension: false,
  },
  {
    id: KnownMetric.PeopleCount,
    label: "People Count",
    isDimension: false,
  },
  {
    id: KnownMetric.AlertsCount,
    label: "Alerts Count",
    isDimension: false,
  },
  {
    id: KnownMetric.ProcessedAlertsCount,
    label: "Processed Alerts Count",
    isDimension: false,
  },
  {
    id: KnownMetric.AvgPlanogramCompliance,
    label: "Avg. Planogram Compliance",
    isDimension: false,
  },
  {
    id: KnownMetric.AvgPrice,
    label: "Avg. Price",
    isDimension: false,
  },
  {
    id: KnownMetric.AvgCompetitorsPrice,
    label: "Avg. Competitors Price",
    isDimension: false,
  },
  {
    id: KnownMetric.BasketShare,
    label: "Basket Share",
    isDimension: false,
  },
  {
    id: KnownMetric.Sales,
    label: "Sales Quantity",
    isDimension: false,
  },
];
export const KnownDimensions: Dimension[] = [
  {
    id: KnownDimension.Time,
    label: "Time",
    isDimension: true,
  },
  {
    id: KnownDimension.Hour,
    label: "Hour",
    isDimension: true,
  },
  {
    id: KnownDimension.Date,
    label: "Date",
    isDimension: true,
  },
  {
    id: KnownDimension.Week,
    label: "Week",
    isDimension: true,
  },
  {
    id: KnownDimension.HourOfTheDay,
    label: "Hour of the day",
    isDimension: true,
  },
  {
    id: KnownDimension.DayOfTheWeek,
    label: "Day of the week",
    isDimension: true,
  },
  {
    id: KnownDimension.Zone,
    label: "Zone",
    isDimension: true,
  },
  {
    id: KnownDimension.ZoneInstance,
    label: "Zone Instance",
    isDimension: true,
  },
  {
    id: KnownDimension.Shop,
    label: "Shop",
    isDimension: true,
  },
  {
    id: KnownDimension.SensorType,
    label: "Sensor type",
    isDimension: true,
  },
  {
    id: KnownDimension.DataSource,
    label: "Data Source",
    isDimension: true,
  },
  {
    id: KnownDimension.ShopName,
    label: "Shop Name",
    isDimension: true,
  },
  {
    id: KnownDimension.ZoneName,
    label: "Zone Name",
    isDimension: true,
  },
  {
    id: KnownDimension.DeviceMac,
    label: "Device Mac",
    isDimension: true,
  },
  {
    id: KnownDimension.Person,
    label: "Person",
    isDimension: true,
  },
  {
    id: KnownDimension.VisitStarted,
    label: "Visit Started",
    isDimension: true,
  },
  {
    id: KnownDimension.VisitEnded,
    label: "Visit Ended",
    isDimension: true,
  },
];
export const possibleColumns = [...KnownDimensions, ...KnownMetrics];
export const KnownMetricsMap: Record<string, Metric> = KnownMetrics.reduce(
  (acc, m) => {
    acc[m.id] = m;
    return acc;
  },
  {} as Record<string, Metric>,
);
export const KnownDimensionsMap: Record<string, Dimension> =
  KnownDimensions.reduce(
    (acc, m) => {
      acc[m.id] = m;
      return acc;
    },
    {} as Record<string, Dimension>,
  );

export const tooltips: Record<string, string> = {
  [KnownColumns.HourOfTheDay]:
    "Hour of the day (e.g., 0, 1, 2... 23, without considering the date).",
  [KnownColumns.DayOfTheWeek]:
    "The day of the week (e.g., Monday, Tuesday, etc.",
  [KnownColumns.VisitStarted]:
    "When a customer satisfaction or neutral state was first detected in the store.",
  [KnownColumns.VisitEnded]:
    "When a customer satisfaction or neutral state was last detected in the store.",
  [KnownColumns.CustomerSatisfaction]:
    "The rate of customer satisfied customers.",
  [KnownColumns.HighPrice]:
    "The rate of customers who left the shelf because of high price.",
  [KnownColumns.AssortmentIssues]:
    "The rate of customers who left the shelf because of assortment issues.",
  [KnownColumns.OtherIssues]:
    "The rate of customers who left the shelf because of other issues.",
  [KnownColumns.InterestedCustomers]:
    "Number of customers stayed before the shelf or who's arousal was detected.",
  [KnownColumns.AvgStareTime]:
    "Reflects the typical duration a customer spends looking at a product or display.",
  [KnownColumns.StaredNumber]:
    "Counts how many customers stopped to look at items on the shelf or display.",
  [KnownColumns.BypassersNumber]:
    "Tallies the average number of people walking by without stopping at the shelf or display.",
  [KnownColumns.EngagementsCount]:
    "Tracks the total number of interactions with the shelf within a given time frame.",
  [KnownColumns.EngagementsPerCustomer]:
    "Indicates the average number of times a customer interacts with products on the shelf.",
  [KnownColumns.AvgSpeed]:
    "Measures the average pace of all customers passing by the shelf or display area.",
  [KnownColumns.AvgCSAT]:
    "Measures the overall customer satisfaction with their shopping experience, rated on a scale from 1 to 100. A higher score indicates greater satisfaction. Score above 80 indicates that a high percentage of customers are satisfied, while a score below 50 suggests that many customers are dissatisfied. Monitoring this score can guide improvements in service, product placement, and store layout to enhance the shopping experience.",
  [KnownColumns.CSATDetected]:
    "Percentage of customers with detected satisfaction.",
  [KnownColumns.CSATNotDetected]: "Percentage of customers with neutral state.",
  [KnownColumns.OverallCsatEventsCount]: "Total number of events.",
  [KnownColumns.PeopleCount]: "Number of people.",
  [KnownColumns.AlertsCount]:
    "The total number of alerts triggered by the system.",
  [KnownColumns.ProcessedAlertsCount]:
    "The total number of alerts that were processed.",
  [KnownColumns.AvgPlanogramCompliance]:
    "The average rate of planogram compliance.",
  [KnownColumns.AvgPrice]: "The average price of the products on the shelf.",
  [KnownColumns.AvgCompetitorsPrice]:
    "The average price of the competitor's products.",
  [KnownColumns.BasketShare]:
    "The share of the basket that the products on the shelf represent.",
  [KnownColumns.Sales]: "The total number of sales.",
};
